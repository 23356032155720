.slide-next-enter {
    opacity: 0;
    transform: translateX(100%);
  }
  .slide-next-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 500ms ease-out, transform 500ms ease-out;
  }
  .slide-next-exit {
    opacity: 1;
    transform: translateX(0);
  }
  .slide-next-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 500ms ease-out, transform 500ms ease-out;
  }
  
  .slide-prev-enter {
    opacity: 0;
    transform: translateX(-100%);
  }
  .slide-prev-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 500ms ease-out, transform 500ms ease-out;
  }
  .slide-prev-exit {
    opacity: 1;
    transform: translateX(0);
  }
  .slide-prev-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 500ms ease-out, transform 500ms ease-out;
  }

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .logo {
    height: 50px;
    margin-right: 15px;
  }
  
  h1 {
    font-size: 20px;
    font-weight: bold;
  }
  
  /* Close Button */
  .close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #666;
  }
  
  .close-btn:hover {
    color: red;
  }
  
  /* Section Styling */
  .section {
    padding: 10px;
    border-left: 4px solid #007bff;
    margin-bottom: 15px;
  }
  
  .section h2 {
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  .highlight {
    border-left-color: red;
    background: rgba(255, 0, 0, 0.1);
  }
  
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }
  
  /* Info Row */
  .info-row {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin-bottom: 10px;
    color: #555;
  }
  
  /* Icons */
  .icon {
    margin-right: 5px;
    color: #007bff;
  }
  
  /* Admission Status */
  .status {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
  }
  
  .admitted {
    background: red;
    color: white;
  }
  
  .not-admitted {
    background: green;
    color: white;
  }
  
  /* Button */
  .button-container {
    text-align: center;
    margin-top: 10px;
  }
  
  .close-button {
    background: #333;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .close-button:hover {
    background: black;
  }
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }